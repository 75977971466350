<template>
  <Layout>
    <div class="container">
      <h1 class="is-clearfix">
        <span class="is-pulled-left">Users</span>
        <router-link
          :to="{ name: 'newUser' }"
          tag="button"
          class="button is-info is-pulled-right"
          >Add a new User</router-link
        >
      </h1>
      <section class="main-table-container">
        <table class="table is-striped is-fullwidth">
          <thead>
            <tr>
              <td>Name</td>
              <td>Username</td>
              <td style="cursor: pointer;" @click="sort()">Company</td>
              <td>Role</td>
              <td v-if="isAdmin">Actions</td>
            </tr>
          </thead>
          <!-- END of thead -->
          <tbody>
            <tr v-for="user in sortedUsers" v-bind:key="user._id">
              <td>{{ user.name.full }}</td>
              <td>{{ user.username }}</td>
              <td>{{ user.company.name }}</td>
              <td>{{ user.role }}</td>
              <td v-if="isAdmin" class="actions">
                <div class="field has-addons">
                  <p class="control">
                    <a
                      class="button tooltip is-tooltip-bottom"
                      data-tooltip="Edit User"
                      @click="editUser(user)"
                    >
                      <span class="icon">
                        <icon :icon="['fas', 'pencil-alt']" />
                      </span>
                    </a>
                  </p>
                  <p class="control">
                    <a
                      class="button tooltip is-danger is-tooltip-bottom"
                      data-tooltip="Delete User"
                      @click="confirmUserRemoval(user)"
                    >
                      <span class="icon">
                        <icon :icon="['fas', 'trash-alt']" />
                      </span>
                    </a>
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
          <!-- END of tbody -->
        </table>
      </section>
    </div>
    <modal
      v-show="showModalConfirmation"
      @closeModal="closeModal"
      v-bind="modalConfig"
    />
  </Layout>
</template>

<script>
import axios from 'axios';

export default {
  name: 'users',
  data() {
    return {
      users: [],
      currentSortDir: 'asc',
      userToDelete: null,
      showModalConfirmation: false,
      modalConfig: {
        bodyText: 'Are you sure you want to delete this user?',
        titleText: 'Delete Confirmation',
        buttons: {
          confirm: {
            text: 'Delete',
            class: 'is-danger'
          },
          reject: {
            text: 'Cancel',
            class: ''
          }
        }
      }
    };
  },
  components: {
    Layout: () => import('@/router/layouts/Main')
  },
  methods: {
    sort() {
      this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
    },
    confirmUserRemoval(user) {
      this.showModalConfirmation = true;
      this.userToDelete = user._id;
    },
    async closeModal(result) {
      if (result.remove) {
        await this.deleteUser();
        this.showModalConfirmation = false;
      } else {
        this.showModalConfirmation = false;
      }
    },
    async deleteUser() {
      let index;
      this.users.forEach((el, i) => {
        if (el._id === this.userToDelete) {
          return (index = i);
        }
      });
      try {
        const response = await axios.delete(
          $formsConfig.core.api.users + this.userToDelete
        );
        if (response.status === 200) {
          this.users.splice(index, 1);
          this.userToDelete = null;
        }
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    editUser(user) {
      this.$router.push({ name: 'editUser', params: { user } });
    }
  },
  computed: {
    sortedUsers() {
      return this.users.sort((a, b) => {
        let modifier = this.currentSortDir === 'desc' ? -1 : 1;
        if (a.company && b.company) {
          if (a.company.name < b.company.name) {
            return -1 * modifier;
          }
          if (a.company.name > b.company.name) {
            return 1 * modifier;
          }
        }
        return '0';
      });
    },
    isAdmin() {
      return $cookies.get($formsConfig.core.cookieName).role === 'admin';
    }
  },
  created() {
    // Fetch the list of forms for the user.
    axios.get($formsConfig.core.api.users).then(response => {
      this.users = response.data;
      this.users.forEach(user => {
        if (!user.hasOwnProperty('company') || !user.company) {
          user.company = {
            name: 'N/A'
          };
        }
      });
    });
  }
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  > .columns {
    height: 100%;
    margin-top: 0;
    > .column {
      height: 100%;
    }
  }
  button {
    text-align: right;
  }
}
.headers {
  font-size: 14px;
  font-weight: bold;
  color: $tittle-font;
}
</style>
